<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Maintenance Categories ({{ totalItems }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'maintenance.category.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Category
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="category-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-spacer />

        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search category"
          @input="search"
        ></v-text-field>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="categories"
        v-model="selected"
        @click:row="editItem"
      >
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(categories.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Maintenance Category list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'CategoriesPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'label',
      sortOrder: false,
      filterBy: [
        {
          value: 'label',
          label: 'Category',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
        {
          value: 'updated_at',
          label: 'Updated date',
        },
      ],
      headers: [
        {
          text: 'Category',
          value: 'label',
        },
        {
          text: 'Updated date',
          value: 'updatedDate',
        },
        {
          text: 'Added date',
          value: 'addedDate',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
    }
  },

  created() {
    this.clearCategories()
    this.fetchCategories(1)
  },

  computed: {
    ...mapState({
      categories: (state) => state.category.list,
      listMeta: (state) => state.category.listMeta,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
  },

  methods: {
    ...mapActions({
      getCategories: 'category/getCategories',
    }),

    ...mapMutations({
      clearCategories: 'category/clearCategoryList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
    }),

    filterCategory(filterValue) {
      this.clearCategories()
      this.fetchCategories(null, filterValue)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetchCategories(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearCategories()
      this.fetchCategories()
    }, 600),

    async fetchCategories(page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        paginate: true,
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getCategories(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    editItem(item) {
      this.$router.push({
        name: 'maintenance.category.details',
        params: { id: item.id },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}
</style>
